import { actions } from './actions';
import { mutations } from './mutations';
import { Ability } from '@casl/ability';

const ability = new Ability();

export const global = {
  namespaced: false,
  state: () => ({
    loaders: {},
    error: {
      message: null
    },
    tenant: {
      event_id: '',
      name: '',
      family_name: '',
      email: '',
      phone_number: '',
      onboarding_status: '0'
    },
    ability
  }),
  getters: {
    getError: state => state.error,
    getLoader: state => action => state.loaders[action],
    getTenant: state => state.tenant,
    getTenantAbilities: state => (action, subject) => state.ability.can(action, subject),
    getTenantOnboardingStatus: state => state.tenant.onboarding_status
  },
  actions,
  mutations
};
