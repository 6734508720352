import { globalTypes } from '../../constants/types';
import Vue from 'vue';
import { AbilityBuilder, Ability } from '@casl/ability';

const abilityBuilder = new AbilityBuilder(Ability);

const { setError, setLoading, setTenantInfo, setTenantAbilities, setTenantOnboardingStatus } = globalTypes.actions;

export const mutations = {
  [setLoading]: (state, { mutation, status }) => {
    Vue.set(state.loaders, mutation, status);
  },

  [setError]: (state, error) => {
    console.log(error);
    state.error = {
      message: error.data.message
    };
  },

  [setTenantInfo]: (state, payload) => {
    Vue.set(state, 'tenant', payload);
  },

  [setTenantAbilities]: (state, { response }) => {
    const abilities = response.data.value;
    if (abilities) {
      for (const ability of abilities) {
        abilityBuilder.can(ability.ability.action, ability.ability.subject, ability.status);
      }
      Vue.set(state, 'ability', abilityBuilder.build());
      // state.ability.update(abilityBuilder.build());
      // console.log(abilityBuilder.build().can('create', 'marketing_campaigns_create_channel_mailchimp'));
    }
  },

  [setTenantOnboardingStatus]: (state, { response }) => {
    const onboardingStatus = response.data.value;
    if (onboardingStatus) {
      Vue.set(state.tenant, 'onboarding_status', onboardingStatus.onboarding_status);
    }
  }
};
