import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import VueFormWizard from 'vue-form-wizard';
import { router } from './routes/router';
import store from './store';
import i18n from './i18n';
import './registerServiceWorker';
import './plugins/mixins';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
// import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

const disableMixin = {
  mounted() {
    if (this.$el && this.$el.classList) {
      if (store.getters.getTenantAbilities('disable', this.$el.outerHTML)) {
        this.$el.classList.add('disabled');
      }
    }
  }
};

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Amplify.configure(aws_exports);
Auth.configure(aws_exports);

Vue.mixin(disableMixin);

Vue.use(VueFormWizard);
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

router.beforeEach((to, from, next) => {
  // Load and mount onboarding-multi-step-form to the onboarding route
  // TODO: try to move to router.js
  if (to.path === '/onboarding') {
    const componentName = 'onboarding-multi-step-form';
    loadThirdPartyApp(componentName);
    mountThirdPartyApp(componentName);
  }
  next();
});

new Vue({
  el: '#app',
  router,
  i18n,
  store,
  Auth,
  render: h => h(App)
});

function loadThirdPartyApp(filename) {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(filename + '-index-script')) {
      const scriptIndex = document.createElement('script');
      scriptIndex.id = filename + '-index-script';
      scriptIndex.src = filename + '/assets/index.js';
      scriptIndex.type = 'module';
      document.body.appendChild(scriptIndex);

      loadCSS(filename + '/assets/index.css');

      scriptIndex.onload = () => {
        resolve();
      };
      scriptIndex.onerror = error => {
        reject(error);
      };
    } else {
      resolve();
    }
  });
}

function mountThirdPartyApp(filename) {
  if (!document.getElementById(filename)) {
    const vue3Root = document.createElement('div');
    vue3Root.id = filename;
    document.body.appendChild(vue3Root);
  }

  const componentName = convertToCamelCase(filename);

  if (window[componentName] && window[componentName].mount) {
    window[componentName].mount('#' + filename);
  }
}

function loadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
}

function convertToCamelCase(str) {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}
